"use client";

import {FileUploader} from "@/components/file-upload/interfaces/FileUploader";
import {getBlobSasUrls} from "@/app/blob-actions";
import {BlobClient, BlockBlobParallelUploadOptions} from "@azure/storage-blob";

export class AzureBlobFileUploaderImpl implements FileUploader {
    async uploadFile(file: File, storageFileRelativePath: string): Promise<string> {
        const {readSasUrl, writeSasUrl} = await getBlobSasUrls(`${storageFileRelativePath}/${file.name}`);
        const client = new BlobClient(writeSasUrl);
        const blobClient = client.getBlockBlobClient();
        const options: BlockBlobParallelUploadOptions = {
            blobHTTPHeaders: {
                blobContentType: file.type,
            },
        }

        const uploadResponse = await blobClient.uploadData(file, options)

        if (uploadResponse._response.status !== 201) {
            throw new Error("File upload failed");
        }

        return readSasUrl;
    }
}
