import {UserAnalyticsService} from "@/components/analytics/interfaces/UserAnalyticsService";
import posthog from "posthog-js";
import {AppUser} from "../../../models/AppUser";
import {User} from "firebase/auth";

export class PosthogUserAnalyticsService implements UserAnalyticsService {
    trackEvent(event: string, properties?: any): void {
        posthog.capture(event, properties)
    }
}

export const initializePostHogUser = (user: User) => {
    posthog.identify(user.uid, {
        email: user.email,
        name: user.displayName,
        accountCreationDate: user.metadata.creationTime
    });
}
