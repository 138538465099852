import {UserAnalyticsService} from "@/components/analytics/interfaces/UserAnalyticsService";
import {clarity} from "react-microsoft-clarity";

export class ClarityUserAnalyticsService implements UserAnalyticsService {
    async trackEvent(event: string, properties?: any) {
        if (!clarity.hasStarted()) return;
        if (properties) {
            window.clarity('event', event, properties);
        } else {
            window.clarity('event', event);
        }
    }
}
