"use client";

import {FileUploader} from "@/components/file-upload/interfaces/FileUploader";
import {CompositeUserAnalyticsService} from "@/components/analytics/impls/CompositeUserAnalyticsService";
import {UserAnalyticsService} from "@/components/analytics/interfaces/UserAnalyticsService";
import {VercelAnalyticsService} from "@/components/analytics/impls/VercelAnalyticsService";
import {ClarityUserAnalyticsService} from "@/components/analytics/impls/ClarityUserAnalyticsService";
import {FbPixelUserAnalyticsService} from "@/components/analytics/impls/FbPixelUserAnalyticsService";
import {FirebaseUiAnalyticsService} from "@/components/analytics/impls/FirebaseUiAnalyticsService";
import {HighlightUserAnalyticsService} from "@/components/analytics/impls/HighlightUserAnalyticsService";
import {DebouncedUserAnalyticsService} from "@/components/analytics/interfaces/DebouncedUserAnalyticsService";
import {AzureBlobFileUploaderImpl} from "@/components/file-upload/impls/AzureBlobFileUploaderImpl";
import {PosthogUserAnalyticsService} from "../components/analytics/impls/PosthogUserAnalyticsService";
import {AmplitudeUserAnalyticsService} from "../components/analytics/impls/AmplitudeUserAnalyticsService";

export function getFileUploader(): FileUploader {
    return new AzureBlobFileUploaderImpl();
}


export function getAnalyticsService(): UserAnalyticsService {
    const services: UserAnalyticsService[] = [
        // add more services here
        new VercelAnalyticsService(),
        new ClarityUserAnalyticsService(),
        new FirebaseUiAnalyticsService(),
        new HighlightUserAnalyticsService(),
        new PosthogUserAnalyticsService(),
        new AmplitudeUserAnalyticsService(),
    ];

    return new CompositeUserAnalyticsService(services);
}


export function getDebouncedUserAnalyticsService(): DebouncedUserAnalyticsService {
    return new FbPixelUserAnalyticsService();
}
