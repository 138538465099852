'use client'

import {Button} from "@/components/ui/button"
import {useEffect} from "react";
import {ANALYTICS_CONSTANTS} from "@/configs/analytics-constants";
import {getAnalyticsService} from "@/lib/client-ioc-container";
import {CONTACT_EMAIL} from "@/configs/constants";

export default function GlobalError({
                                        error,
                                        reset,
                                    }: {
    error: Error & { digest?: string }
    reset: () => void
}) {
    useEffect(() => {
        getAnalyticsService().trackEvent(ANALYTICS_CONSTANTS.GlobalError, {
            errorName: error.name,
            errorMessage: error.message,
            errorStack: error.stack
        })
    }, [error.message, error.name, error.stack]);

    return (
        <html>
        <body>
        <div className={"w-screen h-screen flex flex-col items-center justify-center p-10"}>
            <div className={"md:w-full lg:w-[500px] flex flex-col items-center gap-4"}>
                <div className={"flex flex-col items-center"}>
                    <img src={"assets/advideoslogo.svg"} className={"w-[350px] h-auto object-contain"}/>
                    <h2 className={"text-zinc-400 font-semibold text-xl"}>Oops... Something went wrong!</h2>
                </div>
                <div className={"bg-red-500/30 border-2 border-red-500 rounded-md p-4"}>
                    <span className={"text-red-900 font-semibold"}>{error.name}: </span>
                    <span className={"text-red-900 text-sm"}>{error.message}</span>
                </div>
                <div className="w-full border-t border-gray-300 my-4"/>
                <div className={"flex flex-col gap-3"}>
                    <div className={"font-semibold text-lg"}>Please try the following:</div>
                    <div>1. Click on the button below to refresh the app</div>
                    <div>2. If the error persists, email us at <a className={"underline"}
                                                                  href={`mailto:${CONTACT_EMAIL}?&subject=App%20crash%20support%20request%20`}>{CONTACT_EMAIL}</a>.
                        Please include a screenshot of this page, and provide as much detail as possible!
                    </div>
                    <Button className={"mt-[15px]"} onClick={() => reset()}>Try again</Button></div>
            </div>
        </div>
        </body>
        </html>
    )
}
