import {type ClassValue, clsx} from "clsx"
import {twMerge} from "tailwind-merge"
import {http, https} from "follow-redirects";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export type ValueOf<T> = T[keyof T];

export const sleep = (timeMilliseconds: number) => new Promise(r => setTimeout(r, timeMilliseconds))

export function isValidNumber(value: number) {
    return !isNaN(value) && isFinite(value);
}

export function isValidDateNumber(dateNumber: number) {
    const date = new Date(dateNumber);
    return !isNaN(date.getTime());
}


export function getRedirectedUrl(url: string): Promise<string | undefined> {
    return new Promise<string | undefined>(function (resolve, reject) {
// "Producing Code" (May take some time)
        let handler: typeof http | typeof https = http;
        if (url.startsWith("https")) {
            handler = https;
        } else {
            resolve(undefined);
        }

        handler.get(url, response => {
            response.on('data', chunk => {
                resolve(response.responseUrl);
            }).on('error', err => {
                console.error(err);
                resolve(undefined);
            });
        }).on('error', err => {
            console.error(err);
            resolve(undefined);
        });
    });
}
