"use client";

import {UserAnalyticsService} from "@/components/analytics/interfaces/UserAnalyticsService";

export class CompositeUserAnalyticsService implements UserAnalyticsService {
    private readonly _services: UserAnalyticsService[];

    constructor(services: UserAnalyticsService[]) {
        this._services = services;
    }

    trackEvent(event: string, properties?: any) {
        this._services.map(service => service.trackEvent(event, properties));
    }
}
