import {UserAnalyticsService} from "@/components/analytics/interfaces/UserAnalyticsService";
import {track} from '@vercel/analytics';

export class VercelAnalyticsService implements UserAnalyticsService {
    trackEvent(event: string, properties?: any) {
        if (properties) {
            track(event, properties);
        } else {
            track(event);
        }
    }
}
