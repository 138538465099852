export const ANALYTICS_CONSTANTS = {
    ExtractProductUrl: "extract_product_url",
    UploadProductContent: "upload_product_content",
    ToggleHookEnabled: "toggle_hook_enabled",
    AdjustScriptTone: "adjust_script_tone",
    ToggleSubtitles: "toggle_subtitles",
    ScriptChange: "script_change",
    GenerateVideosWithWatermark: "generate_videos_with_watermark",
    GenerateVideos: "generate_videos",
    DeselectCreator: "deselect_creator",
    SelectCreator: "select_creator",
    DownloadVideo: "download_video",
    AccountMenuClick: "account_menu_click",
    GlobalError: "global_error",
    CompleteRegistration: "complete_registration",
    SignInWithEmail: "sign_in_with_email",
    SignInWithGoogle: "sign_in_with_google",
    SubscriptionClicked: "subscription_clicked",
    SelectTemplate: "select_template",
    InvoicePaid: "invoice_paid",
    TrialStarted: "trial_started",
}