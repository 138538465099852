"use client";

import {UserAnalyticsService} from "@/components/analytics/interfaces/UserAnalyticsService";
import {getAnalytics, Analytics, logEvent, isSupported} from "firebase/analytics";

export class FirebaseUiAnalyticsService implements UserAnalyticsService {
    private _ga: Analytics | undefined;
    private eventQueue: Array<{ event: string; properties?: any }> = [];


    constructor() {
        this.initializeAnalytics().then();
    }

    private async initializeAnalytics() {
        this._ga = await isSupported() ? getAnalytics() : undefined;
        this.processQueue();
    }

    trackEvent(event: string, properties?: any) {
        this._ga && logEvent(this._ga, event, properties);
        if (this._ga) {
            logEvent(this._ga, event, properties);
        } else {
            this.eventQueue.push({event, properties});
        }
    }

    // This method is used to process the event queue once the analytics instance is ready
    // It handles the edge case where events are tracked before the analytics instance is ready
    private processQueue() {
        if (this._ga) {
            this.eventQueue.forEach(event => {
                logEvent(this._ga!, event.event, event.properties);
            });
            this.eventQueue = [];
        }
    }
}
