export const DEFAULT_PROCESS_ALLOWED_MINUTES = 30;
export const DEFAULT_PROCESS_ALLOWED_SECONDS = DEFAULT_PROCESS_ALLOWED_MINUTES * 60;
export const PRODUCT_MINIMUM_MEDIA_COUNT = 0;
export const ACCEPTED_USER_MEDIA_TYPES = process.env.ACCEPTED_USER_MEDIA_TYPES || "image/png,image/jpeg,image/webp,image/tiff,image/bmp,video/mp4,video/quicktime,video/x-msvideo,video/webm,video/mpeg";


export const PLACEHOLDER_VIDEO_URL = "https://c0ctsplo2hxx0vpt.public.blob.vercel-storage.com/placeholder-dzKNvC17e9DIAP6PkUYJjCrxYTUvnD.mp4";
export const CONTACT_EMAIL = process.env.CONTACT_EMAIL ?? "contact@clicks.video";


export const TIKTOK_ANTI_FORGERY_TOKEN_TTL_SECONDS = 60;
export const SESSION_STATE_COOKIE_NAME = "session_state";
