import {UserAnalyticsService} from "@/components/analytics/interfaces/UserAnalyticsService";
import * as amplitude from '@amplitude/analytics-browser';
import {AppUser} from "../../../models/AppUser";
import {User} from "firebase/auth";

export class AmplitudeUserAnalyticsService implements UserAnalyticsService {
    trackEvent(event: string, properties?: any): void {
        amplitude.track(event, properties);
    }
}

export const initializeAmplitudeUser = (user: User) => {
    amplitude.setUserId(user.uid);

    const identifyEvent = new amplitude.Identify();
    if (user.email) {
        identifyEvent.set('email', user.email);
    }
    if (user.displayName) {
        identifyEvent.set('name', user.displayName);
    }
    if (user.metadata.creationTime) {
        identifyEvent.setOnce('accountCreationDate', user.metadata.creationTime);
    }
    amplitude.identify(identifyEvent);
}
